import { extendTheme, CSSObject } from '@chakra-ui/react';
import {
  List,
  Input,
  Button,
  Heading,
  Teaser,
  Accordion,
  Link,
  Textarea,
  Container,
  Select,
  NumberInput,
} from '../components/chakra';
import {
  h4,
  largeText,
  mobileStyle,
  desktopStyle,
  smallText,
  text,
  xLargeText,
  xSmallText,
} from '../components/chakra/TypographyDefinitions';

const palette = {
  greenLight: '#DCEFDF',
  greenSpruce: '#007744',
  greenBirch: '#5BB250',
  greenDark: '#374632',
  greenDarker: '#193219',
  yellowLight: '#FBF0D3',
  yellow: '#EAB425',
  yellowDark: '#DEAB23',
  yellowDarker: '#b1881d',
  redLight: '#FBDFD7',
  redError: '#AA1026',
  redDark: '#6E4632',
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#F8F7F3',
  gray2: '#F4F3EF',
  gray3: '#EAE8E0',
  gray4: '#D8D6CD',
  gray5: '#807D72',
};

const mhyTheme = extendTheme({
  config: {
    cssVarPrefix: 'mhy',
  },
  styles: {
    global: {
      html: {
        fontSize: '100%',
      },
      // styles for the `body`
      body: {
        bg: 'backgroundColor',
        color: 'primaryColor',
        lineHeight: 1.45,
        fontWeight: 400,
        '&.mobile-menu-open': {
          overflow: 'hidden',
        },
      },
      // styles for the `a`
      a: {
        color: 'inherit',
        textDecoration: 'none',
        _activeLink: {
          pointerEvents: 'none',
          cursor: 'default',
        },
      },
      'ol, ul': {
        listStyleType: 'none',
      },
      '.CookieConsent ~ *': {
        pb: '60px',
      },
    },
  },
  fonts: {
    heading: 'Archivo Narrow, Arial, sans-serif',
    body: 'Inter, Arial, sans-serif',
  },
  colors: {
    greenLight: palette.greenLight,
    greenSpruce: palette.greenSpruce,
    greenBirch: palette.greenBirch,
    greenDark: palette.greenDark,
    greenDarker: palette.greenDarker,
    yellowLight: palette.yellowLight,
    yellowDark: palette.yellowDark,
    yellowDarker: palette.yellowDarker,
    black: palette.black,
    white: palette.white,
    gray1: palette.gray1,
    gray2: palette.gray2,
    gray3: palette.gray3,
    gray4: palette.gray4,
    gray5: palette.gray5,
    redError: palette.redError,
    redLight: palette.redLight,
    redDark: palette.redDark,
    separatorColor: palette.gray4,
    inputBorderColor: palette.gray4,
    borderColor: palette.gray2,
    highlightColor: palette.greenSpruce,
    backgroundColor: palette.white,
    secondaryBackgroundColor: palette.gray2,
    altBackgroundColor: palette.yellowLight,
    forestExpertBackgroundColor: palette.yellow,
    forestExpertAltBackgroundColor: palette.yellowDark,
    backgroundColorInverted: palette.greenDarker,
    tableHeading: palette.greenDark,
    primaryColor: palette.black,
    secondaryColor: palette.gray5,
    errorColor: palette.redError,
  },
  shadows: {
    outline: 'inherit',
  },
  sizes: {
    container: {
      sm: '666px',
      lg: '1080px',
      xl: '1312px',
    },
    10: '2.25rem',
    12: '2.8125rem',
  },
  layerStyles: {
    invertedColors: {
      bg: palette.greenDarker,
      color: 'backgroundColor',
    },
    sectionPaddingTop: {
      pt: [5, 5, 10, 10],
    },
    sectionPaddingBottom: {
      pb: [5, 5, 10, 10],
    },
    sectionPadding: {
      py: [5, 5, 10, 10],
    },
    sectionPaddingSm: {
      py: [2.5, 2.5, 5, 5],
    },
    fullBleed: {
      gridColumn: '1 / -1',
    },
    smGrid: {
      gridTemplateColumns: [
        'repeat(1, minmax(0, 1fr))',
        'repeat(2, minmax(0, 1fr))',
        'repeat(3, minmax(0, 1fr))',
        'repeat(4, minmax(0, 1fr))',
      ],
      gridGap: 5,
    },
    lgGrid: {
      gridTemplateColumns: [
        'repeat(1, minmax(0, 1fr))',
        'repeat(2, minmax(0, 1fr))',
        'repeat(2, minmax(0, 1fr))',
        'repeat(3, minmax(0, 1fr))',
      ],
      gridGap: 5,
    },
    fullWidth: {
      position: 'relative',
      gridColumn: '-1 / 1',
    },
  },
  textStyles: {
    xl: xLargeText,
    lg: largeText,
    md: text,
    sm: smallText,
    xs: xSmallText,
    subHeading: mobileStyle(h4),
    lead: { ...largeText, maxWidth: '44ch' },
    error: { color: palette.redError },
    eventDate: {
      ...largeText,
      fontFamily: 'Archivo Narrow, Arial, sans-serif',
    },
    mainNav: {
      navItem: {
        fontSize: ['18px', '18px', '16px', '16px'],
        fontWeight: '500',
      },
      groupHeading: {
        ...mobileStyle(h4),
        whiteSpace: 'nowrap',
        _activeLink: { fontWeight: 600 },
      },
      linkItem: {
        ...desktopStyle(smallText),
        _activeLink: { textDecoration: 'underline' },
      },
      globalLink: '.8125rem',
    },
  },
  components: {
    Badge: {
      baseStyle: {
        padding: '0.25rem 0.5rem',
        borderRadius: 'md',
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: 'sm',
      },
      variants: {
        success: {
          backgroundColor: 'greenLight',
          color: 'greenSpruce',
        },
        alert: {
          backgroundColor: 'yellowLight',
          color: 'yellowDarker',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: 'white',
        },
      },
    },
    Input,
    NumberInput,
    Button,
    Heading,
    Teaser,
    List,
    Accordion,
    Link,
    Textarea,
    Container,
    Select,
  },
});

const { breakpoints } = mhyTheme;

const sortedBreakpointEntries = [
  ['base', '0em'],
  ['sm', '30em'],
  ['md', '48em'],
  ['lg', '62em'],
  ['xl', '80em'],
  ['2xl', '96em'],
];

export const getNextBreakpoint = (
  bp: keyof typeof breakpoints,
): keyof typeof breakpoints => {
  const i = sortedBreakpointEntries.findIndex(([bpKey]) => bpKey === bp);
  return sortedBreakpointEntries[i + 1][0] || bp;
};

const createMaxBreakpoint = (bp: string) =>
  `${Number.parseInt(bp, 10) - 0.001}em`;

export const safariOnlyCss = (
  rules: CSSObject,
  breakpoint?: keyof typeof breakpoints,
  useMaxBreakpoint = false,
): CSSObject => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none) and (display:flow-root)': breakpoint
      ? {
          [`@media (${useMaxBreakpoint ? 'max' : 'min'}-width: ${
            useMaxBreakpoint
              ? createMaxBreakpoint(breakpoints[breakpoint])
              : breakpoints[breakpoint]
          })`]: rules,
        }
      : rules,
  },
});

export default mhyTheme;
